<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        پاسخ به
        {{
          selectedData.user
            ? selectedData.user.first_name + " " + selectedData.user.last_name
            : selectedData.name || ""
        }}
        <v-spacer />

        <v-btn outlined small color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-form-base
            :row="rowAttribute"
            :col="{ cols: 12 }"
            :model="model"
            :schema="schema"
          />
        </v-form>
        <v-row align="center" justify="end" class="mt-2 mb-3">
          <v-btn
            class="ma-2"
            dark
            color="success"
            :loading="loading"
            @click="setResponse"
          >
            ثبت
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import VFormBase from "vuetify-form-base";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      model: {
        desc: "",
      },
      schema: {
        desc: {
          type: "CustomInput",
          inputType: "textarea",
          label: "پاسخ",
          class: "required",
        },
      },
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
  methods: {
    setResponse() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          name:
            (this.$store.state.userData?.first_name || "") +
            " " +
            (this.$store.state.userData?.last_name || ""),
          email: this.$store.state.userData?.email,
          desc: this.model.desc,
          rate: 5,
          status: 1,
          user_id: this.$store.state?.userData?.id || undefined,
          parent_id: this.selectedData?.id || undefined,
        };

        let formdata = formPostPrepare(data);

        let type = this.selectedData?.model_type?.split("\\").reverse() || [];

        if (type.length)
          MyAxios.post(
            "reviews/" +
              type[0].toLowerCase() +
              "s/create/" +
              this.selectedData?.model?.slug,
            formdata
          )
            .then((response) => {
              this.loading = false;
              this.$root.$emit("toast", {
                text: "پاسخ با موفقیت ثبت شد",
                type: "success",
              });
              this.dialog = false;
              this.model.desc = "";
              this.$emit("GetList");
            })
            .catch((error) => {
              this.loading = false;
              this.$root.$emit("toast", {
                text: "اطلاعات را به طور کامل وارد نمایید.",
                type: "error",
              });
              console.log(error.response || error);
            });
      }
    },
  },
};
</script>

<style></style>
