<template>
  <span>
    <comments-replay-modal
      ref="replayModalRef"
      :selectedData="selectedData"
      @GetList="$emit('GetList')"
    />
    <v-dialog v-model="dialog" max-width="600">
      <v-card :loading="loading">
        <v-card-title>
          نظر
          {{
            selectedData.user
              ? selectedData.user.first_name + " " + selectedData.user.last_name
              : selectedData.name || ""
          }}
          <v-spacer />

          <v-btn
            outlined
            small
            class="ml-1"
            color="success"
            @click="showReplayModal"
          >
            پاسخ
          </v-btn>

          <v-btn outlined small color="blue" @click="dialog = false">
            بازگشت
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6" v-if="selectedData.rate" class="text-center">
              <small>امتیاز</small>
              <v-rating
                color="yellow"
                dark
                :value="selectedData.rate"
                readonly
              />
            </v-col>
            <v-col cols="6" class="text-center">
              <small>وضعیت</small>
              <br />
              <v-chip
                v-if="selectedData.status == 0"
                color="yellow darken-3"
                dark
                class="rounded"
                small
              >
                در حال بررسی
              </v-chip>
              <v-chip
                v-else-if="selectedData.status == 1"
                color="success"
                dark
                class="rounded"
                small
              >
                تایید شده
              </v-chip>
              <v-chip v-else color="red" dark class="rounded" small>
                رد شده
              </v-chip>
            </v-col>
            <v-col cols="12" v-if="selectedData.model">
              <small>برای</small>
              <h3 class="black--text">
                {{ selectedData.model.name || selectedData.model.title }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="selectedData.user && selectedData.user.email"
            >
              <small>ایمیل</small>
              <h3 class="black--text">{{ selectedData.user.email }}</h3>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              v-if="selectedData.user && selectedData.user.cellphone"
            >
              <small>شماره تلفن</small>
              <h3 class="black--text">{{ selectedData.user.cellphone }}</h3>
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <div v-if="selectedData.desc">
            <small>متن نظر</small>
            <div class="black--text text-justify">
              {{ selectedData.desc }}
            </div>
          </div>
          <v-row align="center" justify="end" class="mt-8 mb-3">
            <v-btn
              class="ma-2"
              dark
              small
              color="red"
              :loading="loading"
              @click="setStatus(2)"
            >
              <v-icon small dark>mdi-close</v-icon>
              <!-- {{ row.status == 2 ? "آگهی رد شده" : "رد آگهی" }} -->
              رد
            </v-btn>
            <v-btn
              class="ma-2"
              dark
              small
              color="success"
              :loading="loading"
              @click="setStatus(1)"
            >
              <v-icon small dark>mdi-check-bold</v-icon>

              <!-- {{ row.status == 1 ? "آگهی تایید شده" : "تایید آگهی" }} -->
              تایید
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import CommentsReplayModal from "./CommentsReplayModal.vue";
export default {
  components: { CommentsReplayModal },
  data() {
    return {
      dialog: false,
      loading: false,
      replayDialog: false,
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
  methods: {
    showReplayModal() {
      this.$refs.replayModalRef.dialog = true;
    },
    setStatus(status) {
      this.loading = true;
      MyAxios.post("/reviews/" + this.selectedData.id + "/update", {
        status,
      })
        .then((response) => {
          this.loading = false;
          this.dialog = false;
          this.$root.$emit("toast", {
            text: "وضعیت نظر با موفقیت تغییر کرد.",
            type: "success",
          });
          this.$emit("GetList");
          // this.GetList();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
